import { useMemo } from "react";
import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { isBrowser } from "../utils";
import cacheConfig from './cacheConfig'
import Cookies from "universal-cookie";
import { onError } from "@apollo/client/link/error";

let apolloClient: ApolloClient<NormalizedCacheObject>;
// let token: string | null = null;


const authLink = setContext((_, { headers }) => {
  if (isBrowser()) {
    const cookie = new Cookies();
    if (cookie.get('access_token')) return {
      headers: {
        ...headers,
        Authorization: `Bearer ${cookie.get('access_token')}`,
      }
    };
  }

  return { headers };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors.some((err) => err.message === "Context creation failed: Token Expired")) {
      window.location.assign('/sign-out');
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function createApolloClient(headerOptions?: any) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    // write back when auth is ready
    link: from([errorLink, authLink.concat(createHttpLink({
      uri: process.env.GRAPHQL_URL,
      headers: {
        ...headerOptions,
      },
    }))]),
    // link: createHttpLink({
    //   uri: process.env.GRAPHQL_URL,
    //   headers: {
    //     ...headerOptions,
    //   },
    // }),
    cache: new InMemoryCache({ ...cacheConfig }),
  });
}


export function initializeApollo(initialState: any = null, headerOptions?: any) {
  const _apolloClient = apolloClient ?? createApolloClient(headerOptions);

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === "undefined") return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState: any, headerOptions: any) {
  const store = useMemo(() => initializeApollo(initialState, headerOptions), [initialState, headerOptions]);
  return store;
}
