import { InMemoryCacheConfig } from "@apollo/client";

const cache: InMemoryCacheConfig = {
  typePolicies: {
    InboxQuery: {
      merge: true,
    },
    InboxGroup: {
      merge: true,
    },
    Inbox: {
      merge: true,
    },
    KitakerjaUser: {
      merge: true,
    },
    InboxPagination: {
      keyFields: ["is_request"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming = [], { readField }) {
            const existingIdSet = new Set(
              existing.map((inbox: any) => readField("id", inbox))
            );
            const newIncomingData = incoming.filter(
              (inbox: any) => !existingIdSet.has(readField("id", inbox))
            );

            return [...newIncomingData, ...existing];
          },
        },
      },
    },
    MessagePagination: {
      merge: true,
      keyFields: ["inbox_id"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming = [], { readField }) {
            const existingIdSet = new Set(
              existing.map((message: any) => readField("id", message))
            );
            const newIncomingData = incoming.filter(
              (message: any) => !existingIdSet.has(readField("id", message))
            );

            return [...existing, ...newIncomingData].sort((a, b) => {
              const firstMessageDate = new Date(
                readField("created_at", a) || ""
              );
              const secondMessageDate = new Date(
                readField("created_at", b) || ""
              );

              if (firstMessageDate < secondMessageDate) return -1;
              return 1;
            });
          },
        },
      },
    },
    PostPagination: {
      keyFields: ["is_me"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField("id", post))
            );
            const newIncomingData = incoming.filter(
              (post: any) => !existingIdSet.has(readField("id", post))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    CompanyPagination: {
      keyFields: ["require_open_job"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((company: any) => readField("id", company))
            );
            const newIncomingData = incoming.filter(
              (company: any) => !existingIdSet.has(readField("id", company))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    CompanyQuery: {
      merge: true,
    },
    CompanySearchPagination: {
      keyFields: ["filter"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((company: any) => readField("id", company))
            );
            const newIncomingData = incoming.filter(
              (company: any) => !existingIdSet.has(readField("id", company))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    UserQuery: {
      merge: true,
    },
    UserPagination: {
      keyFields: ["name"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((user: any) => readField("id", user))
            );
            const newIncomingData = incoming.filter(
              (user: any) => !existingIdSet.has(readField("id", user))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    JobQuery: {
      merge: true,
    },
    JobSearchPagination: {
      keyFields: ["keyword"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((job: any) => readField("id", job))
            );
            const newIncomingData = incoming.filter(
              (job: any) => !existingIdSet.has(readField("id", job))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    FollowerPagination: {
      keyFields: ["name", "user_type"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((follower: any) => readField("id", follower))
            );
            const newIncomingData = incoming.filter(
              (follower: any) => !existingIdSet.has(readField("id", follower))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    FollowingPagination: {
      keyFields: ["name", "user_type"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((following: any) => readField("id", following))
            );
            const newIncomingData = incoming.filter(
              (following: any) => !existingIdSet.has(readField("id", following))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    CommentPagination: {
      keyFields: ["post_id"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField("id", post))
            );
            const newIncomingData = incoming.filter(
              (post: any) => !existingIdSet.has(readField("id", post))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    EmploymentType: {
      keyFields: ["name"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField("id", post))
            );
            const newIncomingData = incoming.filter(
              (post: any) => !existingIdSet.has(readField("id", post))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    TopupHistoryPagination: {
      keyFields: ["kitakerja_user_id"],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField("id", post))
            );
            const newIncomingData = incoming?.filter(
              (post: any) => !existingIdSet.has(readField("id", post))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    BalanceLogPagination: {
      keyFields: [],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField("id", post))
            );
            const newIncomingData = incoming?.filter(
              (post: any) => !existingIdSet.has(readField("id", post))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    MemberJoinedWithMyReferralPagination: {
      keyFields: [],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField("id", post))
            );
            const newIncomingData = incoming?.filter(
              (post: any) => !existingIdSet.has(readField("id", post))
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },

    // MemberProfileCompletion: {
    //   keyFields: ["member_id"],
    //   fields: {
    //      nodes: {
    //       keyArgs: false,
    //       merge(existing = [], incoming, { readField }) {
    //         const existingIdSet = new Set(existing.map((Member: any) => readField("id", Member)));
    //         const newIncomingData = incoming.filter((Member: any) => !existingIdSet.has(readField("id", Member)));

    //         return [...existing, ...newIncomingData];
    //       },
    //     },
    //   }
    // }
  },
};

export default cache;
