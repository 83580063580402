import Link from 'next/link';
import WhatsappIcon from '@pmberjaya/kitakerja-icons/build/Whatsapp'
import ClearIcon from '@pmberjaya/kitakerja-icons/build/Clear'
import Button from '@pmberjaya/kitakerja-ui/build/Components/Button';
import React, { useState } from 'react';

const WhatsAppButton: React.FC = () => {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <div className="whatsapp">
        <div className="scaffold">
          <Button
            className="rounded-circle p-1 position-absolute wa-btn"
            color="danger"
            onClick={() => setShow(false)}
          >
            <ClearIcon width="24px" height="24px" />
          </Button>
          <Link href={'https://api.whatsapp.com/send/?phone=%2B6281276168350&text&app_absent=0'}>
            <a
              target="_blank"
              className="btn btn-dark p-3 rounded-circle d-flex align-items-center justify-content-center"
            >
              <WhatsappIcon width="28px" height="28px" />
            </a>
          </Link>
        </div>
      </div>
    )
  }
  return null
}

export default WhatsAppButton;
