import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import dynamic from "next/dynamic";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createContext, useContext, useEffect, useReducer } from "react";
import StateReducer, { initialState } from "./reducer";
import MessageReducer, {
  initialState as initialMessageState,
} from "./messageReducer";
import { useApollo } from '../apollo';
import { Provider as FetchProvider } from 'use-http'
import {
  ApolloProvider,
} from '@apollo/client';
import ToastProvider from '@pmberjaya/kitakerja-ui/build/Components/Toast'
import Cookies from "universal-cookie";
import WhatsAppButton from "../components/common/whatsappButton";
import { isBrowser } from "../utils";

const MainHeader = dynamic(() => import("../components/Layout"));
const SnackBarMessage = dynamic(() => import("../components/Snacbar/Index"));
const DateFormatsProvider = dynamic(() =>
  import("@pmberjaya/lib/build/DateFormat/index")
);

let theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[500],
      main: blue[700],
      dark: blue[900],
      contrastText: "#fff",
    },
    secondary: {
      light: grey[50],
      main: grey[100],
      dark: grey[700],
      contrastText: grey[500],
    },
    error: {
      light: red[300],
      main: red[700],
      dark: red[900],
      contrastText: red[700],
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
theme = responsiveFontSizes(theme);

const StateContext = createContext([]);
const MessageContext = createContext([]);

function StateProvider(props) {
  const { children } = props;

  const cookies = new Cookies();
  const at = cookies.get('access_token');

  useEffect(() => {
    if (isBrowser()) {
      if (window.location.hash && window.location.hash == '#_=_') {
        window.location.hash = '';
      }
    }
  }, [])

  const apolloClient = useApollo(props?.initialApolloState, {
    ...(at ? {
      authorization: `Bearer ${at}`
    } : {})
  });

  return (
    <>
      <FetchProvider
        url={process.env.BACKEND_URL}
        options={{
          headers: {
            authorization: `Bearer ${at}`
          }
        }}
      >
        <ApolloProvider client={apolloClient}>
          <StateContext.Provider value={useReducer(StateReducer, initialState)}>
            <MessageContext.Provider
              value={useReducer(MessageReducer, initialMessageState)}
            >
              <ToastProvider>
                <DateFormatsProvider
                  language={{
                    ago: `Ago`,
                    today: `Today`,
                    yesterday: `Yesterday`,
                    minute: `Minutes`,
                    hour: `Hours`,
                    second: `Second`,
                    monday: `Monday`,
                    tuesday: `Tuesday`,
                    wednesday: `Wednesday`,
                    thursday: `Thursday`,
                    friday: `Friday`,
                    saturday: `Saturday`,
                    sunday: `Sunday`,
                    january: `January`,
                    february: `February`,
                    march: `March`,
                    april: `April`,
                    may: `May`,
                    june: `June`,
                    july: `July`,
                    august: `August`,
                    september: `September`,
                    october: `October`,
                    november: `November`,
                    december: `December`,
                  }}
                >
                  <MuiThemeProvider theme={theme}>
                    <MainHeader>
                      {children}
                      <SnackBarMessage />
                    </MainHeader>
                  </MuiThemeProvider>
                </DateFormatsProvider>
              </ToastProvider>
            </MessageContext.Provider>
          </StateContext.Provider>
        </ApolloProvider>
      </FetchProvider>
      <WhatsAppButton />
    </>
  );
}

export function useStateContext() {
  return useContext(StateContext);
}

export function useMessageContext() {
  return useContext(MessageContext);
}

export default StateProvider;
