export const initialState = {
  message: {
    status: false,
    message: "",
    variant: "",
  },
};

function MessageReducer(state = initialState, action) {
  switch (action.type) {
    case "SUCCESS_MESSAGE": {
      return {
        message: {
          status: true,
          message: action.payload,
          variant: "success",
        },
      };
    }
    case "ERROR_MESSAGE": {
      return {
        message: {
          status: true,
          message: action.payload,
          variant: "error",
        },
      };
    }
    case "REMOVE_MESSAGE": {
      return {
        message: {
          status: false,
          message: "",
          variant: "default",
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default MessageReducer;
